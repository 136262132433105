import React from 'react';

const LinksPage = () => {
  return (
    <div>
      <p>This is the links page placeholder</p>
    </div>
  );
};

export default LinksPage;
