import {Button, Textarea, toast} from '@cashiaApp/web-components';
import React, {useEffect, useMemo} from 'react';

import FormInput from './FormInput';
import {ReactComponent as DropdownIcon} from '../../../assets/icons/dropdown.svg';
import {ReactComponent as Info} from '../../../assets/icons/info-circle.svg';
import PlacesAutocomplete from '../../../components/common/AddressInput';
import CustomPhoneInput from '../../../components/common/CustomPhoneInput';
import DropDown from '../../../components/common/DropDown';
import validateKenyanPhoneNumber from '../../../utils/validatePhoneNumber';
import {useSelfOnboardingContentContext} from '../layout';

export interface BussinessInfoForm {
  businessName?: string;
  category?: string;
  description?: string;
  phoneNumber?: {
    countryCode: string;
    number: string;
  };
  location?: string;
  building?: string;
}

interface FormErrors {
  [key: string]: string;
}

type ValidateFunction = (values: BussinessInfoForm) => FormErrors;

const validate: ValidateFunction = (data: BussinessInfoForm) => {
  const errors: FormErrors = {};
  if (!data?.businessName?.trim()) {
    errors.businessName = 'Business name is required';
  }
  if (!data?.category?.trim()) {
    errors.category = 'Category is required';
  }
  if (!data?.description?.trim()) {
    errors.description = 'Description is required';
  }
  if (!data?.phoneNumber?.countryCode?.trim()) {
    errors.phoneNumber = 'Country code is required';
  }
  if (!data?.phoneNumber?.number?.trim()) {
    errors.phoneNumber = 'Phone number is required';
  } else if (
    !validateKenyanPhoneNumber(data?.phoneNumber?.number?.trim() || '')
  ) {
    errors.phoneNumber = 'Phone number is invalid';
  }
  if (!data?.location?.trim()) {
    errors.location = 'Location is required';
  }

  return errors;
};

const categories = [
  'Travel Agencies & Tour Operators',
  'Applications for mobile devices',
  'Computer programming, Data processing, and Integrated Systems Design Services',
  'Miscellaneous General Merchandise',
  'Hardware, Equipment, and supplies',
  'Florists Supplies, Nursery Stock and Flowers',
  'Computer Software Stores',
  'Software - Computer: Computers, peripheral computer equipment, and software',
  'Antique shops-sales, repairs, and restoration services',
  'Professional Services',
  'Schools and educational services',
  'Medical services, and health practitioners',
  'Electronic Stores',
  'Gift card, novelty, and souvenir shops',
  'Luggage and leather goods stores',
  'Variety Stores',
  'Cosmetic stores',
  'Camera and photographic supply stores',
  'Sporting goods stores',
  'Securities Brokers and Dealers.',
];

export default function BusinessInfo() {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [data, setData] = React.useState<BussinessInfoForm>({
    phoneNumber: {
      countryCode: '+254',
      number: '',
    },
  });
  const [errors, setErrors] = React.useState<FormErrors>({});
  const {update, payload} = useSelfOnboardingContentContext();

  useEffect(() => {
    if (payload && payload.buisnessInfo) {
      setData({...payload.buisnessInfo});
    }
  }, [payload]);

  const handleFormSubmit = () => {
    setErrors({});
    const validationErrors = validate(data);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      update?.({...payload, buisnessInfo: {...data}});
      toast.success('Business info captured!');
    }
  };

  const btnDisabled = useMemo(() => {
    return (
      !data?.businessName ||
      !data?.category ||
      !data?.description ||
      !data?.phoneNumber?.number ||
      !data?.phoneNumber?.countryCode ||
      !data?.location
    );
  }, [data]);

  const renderErrorMessage = (fieldName: string) => {
    if (errors[fieldName]) {
      return (
        <div className="flex items-center h-[24px]">
          <Info />
          <span className="text-orangeDark mx-2 p-1 rounded text-[12px] font-normal bg-lightOrange">
            {errors[fieldName]}
          </span>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="flex flex-col justify-between h-full">
      <form>
        <div>
          <p className="font-[600]">Step 1:</p>
          <p className="mb-3 font-[600] text-[40px]">Business Information</p>

          <FormInput
            label="Business Name"
            onChange={(e) => setData({...data, businessName: e})}
            value={data.businessName}
            error={renderErrorMessage('businessName')}
            required
          />

          <div className="mb-3">
            <p className="flex font-[600] mb-1 gap-2 items-center text-sm">
              Business Category * {renderErrorMessage('category')}
            </p>
            <DropDown
              dropDownPosition="center"
              show={dropdownOpen}
              setShow={setDropdownOpen}
              actionElement={
                <div
                  className={`${
                    dropdownOpen ? 'border-red-300' : 'border-dividerGrey'
                  } px-[10px] flex flex-row items-center justify-between w-[490px] bg-transparent border-[1px] rounded-[5px] h-[46px] focus:outline-none focus:ring-0 focus:border-red-300`}>
                  <p className="w-[450px] overflow-hidden truncate text-[14px]">
                    {data.category}
                  </p>
                  <DropdownIcon />
                </div>
              }>
              <div className="shadow-sm cursor-pointer w-[490px] h-[412px] overflow-y-scroll ">
                {categories.sort().map((category) => (
                  <div
                    onClick={() => {
                      setDropdownOpen(false);
                      setData({...data, category});
                    }}
                    key={category}
                    className="hover:text-red-500 cursor-pointer flex items-center bg-white px-4 py-2 border-b-[1px] border-dividerGrey px-3 py-3">
                    <p className="text-[14px] font-[500]">{category}</p>
                  </div>
                ))}
              </div>
            </DropDown>
          </div>
          <div>
            <p className="flex font-[600] mb-1 gap-2 items-center text-sm">
              Business Description * {renderErrorMessage('description')}
            </p>
            <Textarea
              rows={2}
              maxLength={100}
              value={data.description}
              className="min-h-[90px] w-[490px] bg-transparent border-[1px] border-dividerGrey h-auto focus:outline-none focus:ring-0 focus:border-red-300"
              onChange={(e) => setData({...data, description: e.target.value})}
              name="description"
            />
            <div className="relative bottom-6 left-[360px] pl-5 w-[120px]">
              <p className="font-[500] text-[10px] text-foggy">
                {data.description?.length || 0} / 100 characters
              </p>
            </div>
          </div>
          <div className="mb-3">
            <p className="flex font-[600] mb-1 gap-2 items-center text-sm">
              Business Phone Number * {renderErrorMessage('phoneNumber')}
            </p>
            <CustomPhoneInput
              countryCode={data.phoneNumber?.countryCode || ''}
              phoneNumber={data.phoneNumber?.number || ''}
              onChange={(countryCode: string, number: string) =>
                setData({...data, phoneNumber: {countryCode, number}})
              }
            />
          </div>
          <div>
            <p className="flex font-[600] mb-1 gap-2 items-center text-sm">
              Business Location * {renderErrorMessage('location')}
            </p>
            <PlacesAutocomplete
              onSelect={(value) => setData({...data, location: value})}
              defaultValue={data.location}
            />
          </div>

          <FormInput
            label="Complex / Building Name"
            onChange={(e) => setData({...data, building: e})}
            value={data.building}
          />
        </div>
      </form>
      <div className="flex flex-row justify-between">
        <div />
        <Button
          onClick={() => handleFormSubmit()}
          className={`w-[237px] h-[48px] rounded-[8px] flex items-center justify-center ${
            btnDisabled ? 'bg-lightGrey' : 'bg-pink'
          }`}>
          <p
            className={`font-[600] ${
              btnDisabled ? 'text-disabledText' : 'text-white'
            }`}>
            Next
          </p>
        </Button>
      </div>
    </div>
  );
}
