import {Button, Input, toast} from '@cashiaApp/web-components';
import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {ReactComponent as Facebook} from '../../../assets/icons/facebook_pink.svg';
import {ReactComponent as ErrorIcon} from '../../../assets/icons/info-circle.svg';
import {ReactComponent as Instagram} from '../../../assets/icons/instagram_pink.svg';
import {ReactComponent as Snapchat} from '../../../assets/icons/snapchat_pink.svg';
import {useSelfOnboardingContentContext} from '../layout';

export interface SocialMediaForm {
  website?: string;
  instagram?: string;
  snapchat?: string;
  fb?: string;
}

const SocialMedia = () => {
  const [data, setData] = useState<SocialMediaForm>();
  const [WebsiteError, setWebsiteError] = useState('');
  const [NoSocialsError, setNoSocialsError] = useState('');
  const [InstaError, setInstaError] = useState('');
  const [FbError, setFbError] = useState('');
  const [SnapError, setSnapError] = useState('');
  const {update, payload, goBack} = useSelfOnboardingContentContext();

  useEffect(() => {
    if (payload && payload.socialMedia) {
      setData({...payload.socialMedia});
    }
  }, [payload]);

  const handleWebsiteChange = (inputValue: string) => {
    setData({...data, website: inputValue});
    const urlPattern = /^(https?:\/\/|www\.)/;
    const invalidCharactersPattern = /[ !@#]/;
    if (!urlPattern.test(inputValue)) {
      setWebsiteError('Please enter a valid Website address.');
    } else if (invalidCharactersPattern.test(inputValue)) {
      setWebsiteError(
        'Please enter a valid Website URL without spaces or special characters like !, @, #.'
      );
    } else {
      setWebsiteError('');
    }
  };

  const handleInstaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const maxLength = 2048;
    setData({...data, instagram: e.target.value});

    if (inputValue.length > maxLength) {
      setInstaError(
        'The Instagram URL exceeds the maximum allowed length. Please enter a shorter URL.'
      );
      return;
    }
    const instagramUrlRegex =
      /^(https?:\/\/)?(www\.)?instagram\.com\/([a-zA-Z0-9._]+)\/?$/;
    const invalidCharsPattern = /[!@#]/;

    if (inputValue == '') {
      setInstaError('');
    }

    if (
      !instagramUrlRegex.test(inputValue) ||
      invalidCharsPattern.test(inputValue)
    ) {
      setInstaError(
        'Please enter a valid Instagram URL or handle without spaces or invalid characters.'
      );
    } else {
      setInstaError('');
    }
  };
  const handleFbChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const maxLength = 2048;
    setData({...data, fb: e.target?.value});

    if (!inputValue) {
      setFbError('');
    }
    if (inputValue.length > maxLength) {
      setFbError(
        'The Facebook URL exceeds the maximum allowed length. Please enter a shorter URL.'
      );
      return;
    }
    const facebookUrlRegex =
      /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9.]{5,}\/?$/;
    const invalidCharsPattern = /[!@#]/;

    if (
      inputValue &&
      (!facebookUrlRegex.test(inputValue) ||
        invalidCharsPattern.test(inputValue))
    ) {
      setFbError(
        'Please enter a valid Facebook URL or handle without spaces or invalid characters.'
      );
    } else {
      setFbError('');
    }
  };
  const handleSnapChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const maxLength = 2048;
    setData({...data, snapchat: e.target.value});
    if (!inputValue) {
      setSnapError('');
    }
    if (inputValue.length > maxLength) {
      setSnapError(
        'The Snapchat URL exceeds the maximum allowed length. Please enter a shorter URL.'
      );
      return;
    }
    const snapchatUrlRegex =
      /^(https?:\/\/)?(www\.)?snapchat\.com\/add\/[a-zA-Z0-9._-]+\/?$/;
    const invalidCharsPattern = /[!@#]/;

    if (
      inputValue &&
      (!snapchatUrlRegex.test(inputValue) ||
        invalidCharsPattern.test(inputValue))
    ) {
      setSnapError(
        'Please enter a valid Snapchat URL or handle without spaces or invalid characters.'
      );
    } else {
      setSnapError('');
    }
  };

  const validateSocialMedia = useCallback(() => {
    if (!data?.fb && !data?.snapchat && !data?.instagram) {
      setNoSocialsError('Please enter at least one social media profile URL');
    } else {
      setNoSocialsError('');
    }
  }, [data, setNoSocialsError]);

  useEffect(() => {
    validateSocialMedia();
  }, [data, validateSocialMedia]);

  const error = useMemo(
    () => NoSocialsError || InstaError || FbError || SnapError,
    [NoSocialsError, InstaError, FbError, SnapError]
  );

  const isDisabled = useMemo(() => {
    return (
      !!error ||
      (!data?.website && !data?.fb && !data?.snapchat && !data?.instagram)
    );
  }, [error, data]);

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="text-bold flex-col w-[53%]">
        <div>
          <p className="text-black text-sm font-semibold">Step 4:</p>
          <p className="text-black text-3xl font-bold mb-1">Social Media</p>
        </div>

        <div>
          <div className="mb-2">
            <div className="flex items-center">
              <p className="text-base mt-4 font-semibold text-black mb-1 pr-5">
                Website
              </p>
              {WebsiteError && (
                <div className="flex items-center border rounded-lg p-1 bg-lightOrange">
                  <ErrorIcon className="pr-1 " />
                  <p className="text-red-500 text-[10px] font-medium items-center w-[200px]">
                    {WebsiteError}
                  </p>
                </div>
              )}
            </div>

            <Input
              className="w-[490px] bg-transparent border-dividerGrey h-[46px] focus:outline-none focus:ring-0 focus:border-red-300"
              value={data?.website}
              onChange={(e) => handleWebsiteChange(e.target.value)}
            />
          </div>
          <div className="mb-7">
            <div className="flex items-center ">
              <p className="text-base font-semibold mt-5 text-black mb-1 pr-3">
                Social Media
              </p>

              {(NoSocialsError || InstaError || FbError || SnapError) && (
                <div className="flex items-center border rounded-lg p-1 mb-1 bg-lightOrange w-[55%]">
                  <ErrorIcon className="pr-1" />
                  <p className="text-red-500 text-[10px] font-medium w-full">
                    {error}
                  </p>
                </div>
              )}
            </div>
            <div className="mb-4">
              <div className="relative mt-2 rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <Instagram />
                </div>
                <Input
                  className="w-[490px] bg-transparent pl-8 border-dividerGrey h-[46px] focus:outline-none focus:ring-0 focus:border-red-300"
                  value={data?.instagram}
                  onChange={handleInstaChange}
                />
              </div>
            </div>

            <div className="mb-4">
              <div className="relative mt-2 rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <Facebook />
                </div>
                <Input
                  className="w-[490px] bg-transparent pl-8 border-dividerGrey h-[46px] focus:outline-none focus:ring-0 focus:border-red-300"
                  value={data?.fb}
                  onChange={handleFbChange}
                />
              </div>
            </div>

            <div className="mb-4">
              <div className="relative mt-2 rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <Snapchat />
                </div>
                <Input
                  className="w-[490px] bg-transparent pl-8 border-dividerGrey h-[46px] focus:outline-none focus:ring-0 focus:border-red-300"
                  value={data?.snapchat}
                  onChange={handleSnapChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <Button
          className="cursor-pointer w-[237px] h-[48px] border-lightRed border-[1.2px] rounded-[8px] flex items-center justify-center bg-white"
          onClick={goBack}>
          <p className="text-active-red font-[600]">Previous</p>
        </Button>
        <Button
          className={`w-[237px] h-[48px] rounded-[8px] flex items-center justify-center bg-lightGrey cursor-pointer ${
            isDisabled
              ? 'bg-lightGrey font-semibold text-foggy'
              : 'bg-smoothRed text-white'
          }`}
          onClick={() => {
            update?.({...payload, socialMedia: {...data}}, true);
            toast.success(
              'Bussiness profile information successfully captured'
            );
          }}>
          <p
            className={`${
              isDisabled ? 'text-disabledText' : 'text-white'
            } font-[600]`}>
            Next
          </p>
        </Button>
      </div>
    </div>
  );
};

export default SocialMedia;
