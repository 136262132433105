import {Resolvers} from '@apollo/client';
import {nanoid} from 'nanoid/non-secure';

import {
  ApprovalState,
  Country,
  CurrencyCode,
  MerchantType,
  Money,
  NotificationChannel,
  RootQueryTypeResolvers,
  VerificationId,
  WalletActivationStatus,
} from '../../../graphql/generated';

const notificationChannel: NotificationChannel = {
  __typename: 'NotificationChannel',
  email: false,
  pushNotification: false,
  phoneCall: false,
  textMessage: false,
};

const money = (amount: number): Money => ({
  __typename: 'Money',
  amountInCents: amount,
  currencyCode: CurrencyCode.Kes,
});

const queryResolvers: RootQueryTypeResolvers = {
  me: () => {
    return {
      __typename: 'Merchant',
      id: nanoid(),
      products: {
        __typename: 'ProductList',
        items: [],
        total: 0,
      },
      settings: {
        __typename: 'Settings',
        notifications: {
          __typename: 'NotificationSettings',
          account: notificationChannel,
          general: notificationChannel,
          promotionalMessages: notificationChannel,
          reminders: notificationChannel,
        },
      },
      tag: '@tacos',
      name: 'Mexican food restaurant',
      shortDescription: 'Tacos and burritos',
      description:
        'We make delicous tacos, burritos and churros so that you can stay fed!',
      reviews: [],
      rating: 3.4,
      favorite: false,
      agreedToTerms: true,
      phone: {
        __typename: 'PhoneNumber',
        countryCode: '+1',
        number: '2345678910',
      },
      productCategories: [],
      operatingTime: null,
      logo: {
        __typename: 'Image',
        id: nanoid(),
        small:
          'https://image.shutterstock.com/image-vector/vintage-mexican-food-poster-design-600w-1049319014.jpg',
        medium:
          'https://image.shutterstock.com/image-vector/vintage-mexican-food-poster-design-600w-1049319014.jpg',
        large:
          'https://image.shutterstock.com/image-vector/vintage-mexican-food-poster-design-600w-1049319014.jpg',
      },
      coverImage: null,
      email: null,
      addresses: [],
      merchantType: MerchantType.Individual,
      idType: VerificationId.Passport,
      idNumber: '1234567890',
      applicantsName: 'John Doe',
      showTutorial: false,
      approvalMessage: '',
      approvalState: ApprovalState.Approved,
      merchantDocuments: [],
      merchantCategories: [],
      wallet: {
        __typename: 'Wallet',
        dailySpend: {
          __typename: 'DailySpend',
          highestEver: {
            __typename: 'HighestEver',
            totalAmount: money(10000),
            transactionDate: new Date().toISOString(),
          },
          spentToday: money(1),
        },
        totalHeld: money(0),
        holds: [],
        transactionActivity: [],
        activation: {
          __typename: 'WalletActivation',
          country: Country.Kenya,
          idPhotos: [],
          phone: {
            __typename: 'PhoneNumber',
            countryCode: '+254',
            number: '1234567890',
          },
          status: WalletActivationStatus.Verified,
        },
        credit: money(100000),
      },
      createdAt: new Date().toISOString(),
      website: 'https://tacos.com',
      user: {
        __typename: 'User',
        id: nanoid(),
        name: 'Melissa Mukami',
        email: 'random@example.com',
        locked: false,
        phone: {
          __typename: 'PhoneNumber',
          countryCode: '+254',
          number: '1234567890',
        },
      },
    };
  },
};

const resolvers: Resolvers = {
  Query: {...queryResolvers},
};

export default resolvers;
