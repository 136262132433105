export const tempBankData = [
  {
    __typename: 'InterswitchBank',
    id: '935',
    logo: {
      __typename: 'Image',
      id: '93ec74f3-cbf4-4a89-b75a-68fa52cf2f8b',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/93ec74f3-cbf4-4a89-b75a-68fa52cf2f8b/abc.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/93ec74f3-cbf4-4a89-b75a-68fa52cf2f8b/abc.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/93ec74f3-cbf4-4a89-b75a-68fa52cf2f8b/abc.png',
    },
    name: 'ABC Bank',
  },
  {
    __typename: 'InterswitchBank',
    id: '903',
    logo: {
      __typename: 'Image',
      id: '6d135a94-ee14-47e2-b0e0-4fcde7c81463',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/6d135a94-ee14-47e2-b0e0-4fcde7c81463/absa.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/6d135a94-ee14-47e2-b0e0-4fcde7c81463/absa.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/6d135a94-ee14-47e2-b0e0-4fcde7c81463/absa.png',
    },
    name: 'ABSA',
  },
  {
    __typename: 'InterswitchBank',
    id: '906',
    logo: {
      __typename: 'Image',
      id: '4902b474-63ad-4ec3-b080-9dcbcf1f14cc',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/4902b474-63ad-4ec3-b080-9dcbcf1f14cc/bank_of_baroda.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/4902b474-63ad-4ec3-b080-9dcbcf1f14cc/bank_of_baroda.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/4902b474-63ad-4ec3-b080-9dcbcf1f14cc/bank_of_baroda.png',
    },
    name: 'Bank of Baroda',
  },
  {
    __typename: 'InterswitchBank',
    id: '905',
    logo: {
      __typename: 'Image',
      id: 'ea091ec4-ca6b-45ed-8098-e57b5099addd',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/ea091ec4-ca6b-45ed-8098-e57b5099addd/bank_of_india.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/ea091ec4-ca6b-45ed-8098-e57b5099addd/bank_of_india.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/ea091ec4-ca6b-45ed-8098-e57b5099addd/bank_of_india.png',
    },
    name: 'Bank of India',
  },
  {
    __typename: 'InterswitchBank',
    id: '919',
    logo: {
      __typename: 'Image',
      id: 'f50f090a-26bb-413c-b7c4-2d49f6d6cdcd',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/f50f090a-26bb-413c-b7c4-2d49f6d6cdcd/boa.jpeg',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/f50f090a-26bb-413c-b7c4-2d49f6d6cdcd/boa.jpeg',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/f50f090a-26bb-413c-b7c4-2d49f6d6cdcd/boa.jpeg',
    },
    name: 'BOA',
  },
  {
    __typename: 'InterswitchBank',
    id: '916',
    logo: {
      __typename: 'Image',
      id: '1bf2cff9-6477-4add-a9c8-9f7a11d25b89',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/1bf2cff9-6477-4add-a9c8-9f7a11d25b89/citi_bank.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/1bf2cff9-6477-4add-a9c8-9f7a11d25b89/citi_bank.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/1bf2cff9-6477-4add-a9c8-9f7a11d25b89/citi_bank.png',
    },
    name: 'Citi Bank',
  },
  {
    __typename: 'InterswitchBank',
    id: '923',
    logo: {
      __typename: 'Image',
      id: '6a0e58cf-557e-4c99-9a92-cc15d501a190',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/6a0e58cf-557e-4c99-9a92-cc15d501a190/consolidated_bank.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/6a0e58cf-557e-4c99-9a92-cc15d501a190/consolidated_bank.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/6a0e58cf-557e-4c99-9a92-cc15d501a190/consolidated_bank.png',
    },
    name: 'Consolidated',
  },
  {
    __typename: 'InterswitchBank',
    id: '911',
    logo: {
      __typename: 'Image',
      id: '1da2f0aa-5e5a-41de-863b-99f2acea925b',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/1da2f0aa-5e5a-41de-863b-99f2acea925b/co-op.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/1da2f0aa-5e5a-41de-863b-99f2acea925b/co-op.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/1da2f0aa-5e5a-41de-863b-99f2acea925b/co-op.png',
    },
    name: 'Co-op Bank',
  },
  {
    __typename: 'InterswitchBank',
    id: '925',
    logo: {
      __typename: 'Image',
      id: '1c273751-3898-4286-905d-5d1d97833a02',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/1c273751-3898-4286-905d-5d1d97833a02/credit_bank.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/1c273751-3898-4286-905d-5d1d97833a02/credit_bank.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/1c273751-3898-4286-905d-5d1d97833a02/credit_bank.png',
    },
    name: 'Credit Bank',
  },
  {
    __typename: 'InterswitchBank',
    id: '959',
    logo: {
      __typename: 'Image',
      id: 'bd34a837-4c63-4da0-83e3-13b6034443de',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/bd34a837-4c63-4da0-83e3-13b6034443de/dbk.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/bd34a837-4c63-4da0-83e3-13b6034443de/dbk.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/bd34a837-4c63-4da0-83e3-13b6034443de/dbk.png',
    },
    name: 'Development Bank',
  },
  {
    __typename: 'InterswitchBank',
    id: '963',
    logo: {
      __typename: 'Image',
      id: '9c5f6775-af64-47d9-9615-7fbdda1be124',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/9c5f6775-af64-47d9-9615-7fbdda1be124/dtb.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/9c5f6775-af64-47d9-9615-7fbdda1be124/dtb.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/9c5f6775-af64-47d9-9615-7fbdda1be124/dtb.png',
    },
    name: 'DTB',
  },
  {
    __typename: 'InterswitchBank',
    id: '943',
    logo: {
      __typename: 'Image',
      id: 'ebcaf74d-4c5b-474d-97cd-a4e17fcf0b97',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/ebcaf74d-4c5b-474d-97cd-a4e17fcf0b97/ecobank.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/ebcaf74d-4c5b-474d-97cd-a4e17fcf0b97/ecobank.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/ebcaf74d-4c5b-474d-97cd-a4e17fcf0b97/ecobank.png',
    },
    name: 'Eco Bank',
  },
  {
    __typename: 'InterswitchBank',
    id: '968',
    logo: {
      __typename: 'Image',
      id: '7cd33e62-7acb-4507-9f56-f06714591f70',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/7cd33e62-7acb-4507-9f56-f06714591f70/equity.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/7cd33e62-7acb-4507-9f56-f06714591f70/equity.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/7cd33e62-7acb-4507-9f56-f06714591f70/equity.png',
    },
    name: 'Equity Bank',
  },
  {
    __typename: 'InterswitchBank',
    id: '970',
    logo: {
      __typename: 'Image',
      id: '5e555e61-3255-41fb-899a-7582ae9de58c',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/5e555e61-3255-41fb-899a-7582ae9de58c/family_bank.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/5e555e61-3255-41fb-899a-7582ae9de58c/family_bank.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/5e555e61-3255-41fb-899a-7582ae9de58c/family_bank.png',
    },
    name: 'Family Bank',
  },
  {
    __typename: 'InterswitchBank',
    id: '979',
    logo: {
      __typename: 'Image',
      id: '08b5b1b9-6d6a-4db2-9926-28e8f655abb4',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/08b5b1b9-6d6a-4db2-9926-28e8f655abb4/faulu_bank.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/08b5b1b9-6d6a-4db2-9926-28e8f655abb4/faulu_bank.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/08b5b1b9-6d6a-4db2-9926-28e8f655abb4/faulu_bank.png',
    },
    name: 'Faulu Bank',
  },
  {
    __typename: 'InterswitchBank',
    id: '974',
    logo: {
      __typename: 'Image',
      id: '31775804-d9bf-4e71-a636-0ec6313c7845',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/31775804-d9bf-4e71-a636-0ec6313c7845/fist_community_bank.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/31775804-d9bf-4e71-a636-0ec6313c7845/fist_community_bank.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/31775804-d9bf-4e71-a636-0ec6313c7845/fist_community_bank.png',
    },
    name: 'First Community Bank',
  },
  {
    __typename: 'InterswitchBank',
    id: '942',
    logo: {
      __typename: 'Image',
      id: '6f63583b-b8ab-444d-92c5-7b9422209e47',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/6f63583b-b8ab-444d-92c5-7b9422209e47/giro_bank.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/6f63583b-b8ab-444d-92c5-7b9422209e47/giro_bank.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/6f63583b-b8ab-444d-92c5-7b9422209e47/giro_bank.png',
    },
    name: 'Giro Bank',
  },
  {
    __typename: 'InterswitchBank',
    id: '953',
    logo: {
      __typename: 'Image',
      id: '6e96af37-e248-4ffc-8505-4d2265237819',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/6e96af37-e248-4ffc-8505-4d2265237819/gt_bank.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/6e96af37-e248-4ffc-8505-4d2265237819/gt_bank.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/6e96af37-e248-4ffc-8505-4d2265237819/gt_bank.png',
    },
    name: 'GT Bank',
  },
  {
    __typename: 'InterswitchBank',
    id: '955',
    logo: {
      __typename: 'Image',
      id: '28d86baf-a498-47af-8c43-5e2d32e503a3',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/28d86baf-a498-47af-8c43-5e2d32e503a3/guardian_bank.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/28d86baf-a498-47af-8c43-5e2d32e503a3/guardian_bank.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/28d86baf-a498-47af-8c43-5e2d32e503a3/guardian_bank.png',
    },
    name: 'Guardian Bank',
  },
  {
    __typename: 'InterswitchBank',
    id: '972',
    logo: {
      __typename: 'Image',
      id: '3e526b1f-0f93-4f1d-8a73-9ab6f782a5b8',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/3e526b1f-0f93-4f1d-8a73-9ab6f782a5b8/gulf_african_bank.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/3e526b1f-0f93-4f1d-8a73-9ab6f782a5b8/gulf_african_bank.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/3e526b1f-0f93-4f1d-8a73-9ab6f782a5b8/gulf_african_bank.png',
    },
    name: 'Gulf African Bank',
  },
  {
    __typename: 'InterswitchBank',
    id: '908',
    logo: {
      __typename: 'Image',
      id: 'd3b48519-7ede-48a0-be0e-9ac89712d58c',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/d3b48519-7ede-48a0-be0e-9ac89712d58c/habib_bank.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/d3b48519-7ede-48a0-be0e-9ac89712d58c/habib_bank.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/d3b48519-7ede-48a0-be0e-9ac89712d58c/habib_bank.png',
    },
    name: 'Habib Bank',
  },
  {
    __typename: 'InterswitchBank',
    id: '917',
    logo: {
      __typename: 'Image',
      id: 'e875356b-de20-4d69-a4e9-1602b4c7baa0',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/e875356b-de20-4d69-a4e9-1602b4c7baa0/habib_bank_ag_zurich.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/e875356b-de20-4d69-a4e9-1602b4c7baa0/habib_bank_ag_zurich.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/e875356b-de20-4d69-a4e9-1602b4c7baa0/habib_bank_ag_zurich.png',
    },
    name: 'Habib Bank AG Zurich',
  },
  {
    __typename: 'InterswitchBank',
    id: '961',
    logo: {
      __typename: 'Image',
      id: 'a5a4a4b7-f9cb-4df4-82dd-920690fc779c',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/a5a4a4b7-f9cb-4df4-82dd-920690fc779c/housing_finance_bank.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/a5a4a4b7-f9cb-4df4-82dd-920690fc779c/housing_finance_bank.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/a5a4a4b7-f9cb-4df4-82dd-920690fc779c/housing_finance_bank.png',
    },
    name: 'Housing Finance',
  },
  {
    __typename: 'InterswitchBank',
    id: '957',
    logo: {
      __typename: 'Image',
      id: '5b2145ea-45e9-4d7c-ad85-081dcc28e437',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/5b2145ea-45e9-4d7c-ad85-081dcc28e437/i&m.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/5b2145ea-45e9-4d7c-ad85-081dcc28e437/i&m.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/5b2145ea-45e9-4d7c-ad85-081dcc28e437/i&m.png',
    },
    name: 'I&M',
  },
  {
    __typename: 'InterswitchBank',
    id: '951',
    logo: {
      __typename: 'Image',
      id: 'e9d0aac0-cd9a-4195-a5fb-f8106d86a9f4',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/e9d0aac0-cd9a-4195-a5fb-f8106d86a9f4/jamii_bora_bank.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/e9d0aac0-cd9a-4195-a5fb-f8106d86a9f4/jamii_bora_bank.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/e9d0aac0-cd9a-4195-a5fb-f8106d86a9f4/jamii_bora_bank.png',
    },
    name: 'Jamii Bora',
  },
  {
    __typename: 'InterswitchBank',
    id: '901',
    logo: {
      __typename: 'Image',
      id: 'c82ff4ff-9892-4b91-8120-d27b7e753d9a',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/c82ff4ff-9892-4b91-8120-d27b7e753d9a/kcb.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/c82ff4ff-9892-4b91-8120-d27b7e753d9a/kcb.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/c82ff4ff-9892-4b91-8120-d27b7e753d9a/kcb.png',
    },
    name: 'KCB',
  },
  {
    __typename: 'InterswitchBank',
    id: '978',
    logo: {
      __typename: 'Image',
      id: 'bd171b30-e6e1-48b4-b71f-da530da52d7c',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/bd171b30-e6e1-48b4-b71f-da530da52d7c/kwft.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/bd171b30-e6e1-48b4-b71f-da530da52d7c/kwft.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/bd171b30-e6e1-48b4-b71f-da530da52d7c/kwft.png',
    },
    name: 'KWFT',
  },
  {
    __typename: 'InterswitchBank',
    id: '912',
    logo: {
      __typename: 'Image',
      id: '4266200f-e50e-43d4-802a-e7b793c5ba4b',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/4266200f-e50e-43d4-802a-e7b793c5ba4b/nbk.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/4266200f-e50e-43d4-802a-e7b793c5ba4b/nbk.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/4266200f-e50e-43d4-802a-e7b793c5ba4b/nbk.png',
    },
    name: 'NBK',
  },
  {
    __typename: 'InterswitchBank',
    id: '907',
    logo: {
      __typename: 'Image',
      id: 'f7a0350d-9caf-447d-947d-ca6440df181c',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/f7a0350d-9caf-447d-947d-ca6440df181c/ncba.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/f7a0350d-9caf-447d-947d-ca6440df181c/ncba.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/f7a0350d-9caf-447d-947d-ca6440df181c/ncba.png',
    },
    name: 'NCBA Bank',
  },
  {
    __typename: 'InterswitchBank',
    id: '914',
    logo: {
      __typename: 'Image',
      id: '31ac463b-e0e5-45f6-881b-d1771d6ce51e',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/31ac463b-e0e5-45f6-881b-d1771d6ce51e/oriental_bank.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/31ac463b-e0e5-45f6-881b-d1771d6ce51e/oriental_bank.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/31ac463b-e0e5-45f6-881b-d1771d6ce51e/oriental_bank.png',
    },
    name: 'Oriental',
  },
  {
    __typename: 'InterswitchBank',
    id: '950',
    logo: {
      __typename: 'Image',
      id: 'f1ade2f5-9b69-4a67-8388-e6ce924d6421',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/f1ade2f5-9b69-4a67-8388-e6ce924d6421/paramount_bank.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/f1ade2f5-9b69-4a67-8388-e6ce924d6421/paramount_bank.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/f1ade2f5-9b69-4a67-8388-e6ce924d6421/paramount_bank.png',
    },
    name: 'Paramount',
  },
  {
    __typename: 'InterswitchBank',
    id: '999',
    logo: {
      __typename: 'Image',
      id: 'fe45fee4-538c-4454-a1c5-a4e44fe69a8c',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/fe45fee4-538c-4454-a1c5-a4e44fe69a8c/post_bank.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/fe45fee4-538c-4454-a1c5-a4e44fe69a8c/post_bank.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/fe45fee4-538c-4454-a1c5-a4e44fe69a8c/post_bank.png',
    },
    name: 'Post Bank',
  },
  {
    __typename: 'InterswitchBank',
    id: '910',
    logo: {
      __typename: 'Image',
      id: 'b0195bfe-7aca-46a8-89b1-6ede7a37ce56',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/b0195bfe-7aca-46a8-89b1-6ede7a37ce56/prime_bank.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/b0195bfe-7aca-46a8-89b1-6ede7a37ce56/prime_bank.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/b0195bfe-7aca-46a8-89b1-6ede7a37ce56/prime_bank.png',
    },
    name: 'Prime Bank',
  },
  {
    __typename: 'InterswitchBank',
    id: '960',
    logo: {
      __typename: 'Image',
      id: 'af6d45ef-3fd5-46af-a07e-e9f8dee34fbb',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/af6d45ef-3fd5-46af-a07e-e9f8dee34fbb/sbm_bank.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/af6d45ef-3fd5-46af-a07e-e9f8dee34fbb/sbm_bank.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/af6d45ef-3fd5-46af-a07e-e9f8dee34fbb/sbm_bank.png',
    },
    name: 'SBM',
  },
  {
    __typename: 'InterswitchBank',
    id: '966',
    logo: {
      __typename: 'Image',
      id: 'f6b0c8e4-4869-41ce-a369-dacc54066a66',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/f6b0c8e4-4869-41ce-a369-dacc54066a66/sidian_bank.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/f6b0c8e4-4869-41ce-a369-dacc54066a66/sidian_bank.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/f6b0c8e4-4869-41ce-a369-dacc54066a66/sidian_bank.png',
    },
    name: 'Sidian',
  },
  {
    __typename: 'InterswitchBank',
    id: '949',
    logo: {
      __typename: 'Image',
      id: '62f35ba6-e57a-47d9-8e2f-f54bab0c1ddc',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/62f35ba6-e57a-47d9-8e2f-f54bab0c1ddc/spire_bank.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/62f35ba6-e57a-47d9-8e2f-f54bab0c1ddc/spire_bank.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/62f35ba6-e57a-47d9-8e2f-f54bab0c1ddc/spire_bank.png',
    },
    name: 'Spire Bank',
  },
  {
    __typename: 'InterswitchBank',
    id: '931',
    logo: {
      __typename: 'Image',
      id: 'd69eb502-50ee-4ff7-9738-2eaa6146732a',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/d69eb502-50ee-4ff7-9738-2eaa6146732a/stanbic.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/d69eb502-50ee-4ff7-9738-2eaa6146732a/stanbic.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/d69eb502-50ee-4ff7-9738-2eaa6146732a/stanbic.png',
    },
    name: 'Stanbic',
  },
  {
    __typename: 'InterswitchBank',
    id: '902',
    logo: {
      __typename: 'Image',
      id: 'd03e97f3-1c75-4b3c-bba8-db6a5546616f',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/d03e97f3-1c75-4b3c-bba8-db6a5546616f/standard_chartered.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/d03e97f3-1c75-4b3c-bba8-db6a5546616f/standard_chartered.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/d03e97f3-1c75-4b3c-bba8-db6a5546616f/standard_chartered.png',
    },
    name: 'Stanchart',
  },
  {
    __typename: 'InterswitchBank',
    id: '926',
    logo: {
      __typename: 'Image',
      id: 'a9694b2b-95d1-4539-88a4-757bff6d39a4',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/a9694b2b-95d1-4539-88a4-757bff6d39a4/transnational_bank.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/a9694b2b-95d1-4539-88a4-757bff6d39a4/transnational_bank.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/a9694b2b-95d1-4539-88a4-757bff6d39a4/transnational_bank.png',
    },
    name: 'Transnational Bank',
  },
  {
    __typename: 'InterswitchBank',
    id: '976',
    logo: {
      __typename: 'Image',
      id: '0b694bf1-5b03-40ec-9819-070fbf722548',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/0b694bf1-5b03-40ec-9819-070fbf722548/uba.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/0b694bf1-5b03-40ec-9819-070fbf722548/uba.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/0b694bf1-5b03-40ec-9819-070fbf722548/uba.png',
    },
    name: 'UBA',
  },
  {
    __typename: 'InterswitchBank',
    id: '954',
    logo: {
      __typename: 'Image',
      id: 'eeb150bc-dd2f-4eb7-9f1e-f89968c4856b',
      large:
        'https://image.stg.cashia.com/1600,fit/https://storage.googleapis.com/cashiacdn/mvp/eeb150bc-dd2f-4eb7-9f1e-f89968c4856b/victoria_bank.png',
      medium:
        'https://image.stg.cashia.com/800,fit/https://storage.googleapis.com/cashiacdn/mvp/eeb150bc-dd2f-4eb7-9f1e-f89968c4856b/victoria_bank.png',
      small:
        'https://image.stg.cashia.com/200,fit/https://storage.googleapis.com/cashiacdn/mvp/eeb150bc-dd2f-4eb7-9f1e-f89968c4856b/victoria_bank.png',
    },
    name: 'Victoria',
  },
];
