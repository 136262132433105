import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  Observable,
  FetchResult,
  ApolloError,
} from '@apollo/client';
import {toast} from '@cashiaApp/web-components';
import {createLink} from 'apollo-absinthe-upload-link';
import React, {
  useContext,
  useCallback,
  useState,
  PropsWithChildren,
} from 'react';

import {
  localStorageRefreshTokenKey,
  localStorageAccessTokenKey,
  apiUrl,
} from './constants';
import resolvers from './mocks/resolvers';
import typeDefs from './mocks/typeDefs';
import {
  RequestLoginCodeForMerchantDocument,
  SigninOrSignupWithProviderDocument,
  LoginWithCodeForMerchantDocument,
  RefreshTokenMutation,
  RefreshTokenMutationVariables,
  RefreshTokenDocument,
  LoginWithCodeForMerchantMutation,
  SigninOrSignupWithProviderMutation,
  AuthProvider as Provider,
  RequestLoginCodeForMerchantMutation,
} from '../graphql/generated';

interface NewTokens {
  newAccessToken: string;
  newRefreshToken: string;
}

interface ExtendedAuth extends gapi.auth2.GoogleAuth {
  signOut(): Promise<void>;
  disconnect(): void;
}

interface Context {
  isAuthenticated: boolean;
  logout(): Promise<void>;
  sendPhoneNumber(phoneNumber: string): Promise<void>;
  makeLoginWithCode(code: string, phoneNumber: string): Promise<void>;
  signupWithProvider(providerToken: string, provider: Provider): Promise<void>;
  getNewTokens(): Promise<NewTokens | undefined>;
  mockLogin(): void;
}

export const AuthContext = React.createContext<Context>({
  isAuthenticated: false,
  logout: async () => undefined,
  sendPhoneNumber: async () => undefined,
  makeLoginWithCode: async () => undefined,
  signupWithProvider: async () => undefined,
  getNewTokens: async () => undefined,
  mockLogin: () => undefined,
});

export const useAuth = (): Context => useContext(AuthContext);
export const promiseToObservable = (
  promise: Promise<NewTokens | undefined>
): Observable<unknown> =>
  new Observable((subscriber) => {
    promise.then(
      (value) => {
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      },
      (err) => subscriber.error(err)
    );
  });

const AuthProvider = ({children}: PropsWithChildren) => {
  const [, setAccessToken] = useState<string | null | undefined>();
  const [, setRefreshToken] = useState<string | null | undefined>();

  // Refresh tokens
  const getNewTokens = async () => {
    const httpLink = createHttpLink({
      uri: apiUrl,
    });
    const client = new ApolloClient({
      cache: new InMemoryCache(),
      link: httpLink,
    });
    const localStorageRefreshToken = localStorage.getItem(
      localStorageRefreshTokenKey
    );
    if (!localStorageRefreshToken) return;
    let res: FetchResult<RefreshTokenMutation, Record<string, unknown>>;
    try {
      res = await client.mutate<
        RefreshTokenMutation,
        RefreshTokenMutationVariables
      >({
        mutation: RefreshTokenDocument,
        variables: {
          input: {
            refreshToken: localStorageRefreshToken,
          },
        },
      });
    } catch (e) {
      await logout();
      return;
    }

    const newAccessToken = res.data?.refreshToken?.accessToken;
    const newRefreshToken = res.data?.refreshToken?.refreshToken;
    if (newAccessToken && newRefreshToken) {
      setTokens(newAccessToken, newRefreshToken);
      return {newAccessToken, newRefreshToken};
    }
  };

  const httpLink = createLink({
    uri: apiUrl || 'http://localhost:4000/graphql',
  });
  const client = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
    resolvers,
    typeDefs,
  });

  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem(localStorageAccessTokenKey)
  );

  // Utiliity
  const setTokens = useCallback((accessToken: string, refreshToken: string) => {
    localStorage.setItem(localStorageAccessTokenKey, accessToken);
    localStorage.setItem(localStorageRefreshTokenKey, refreshToken);
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
    setIsAuthenticated(true);
  }, []);

  const removeTokens = useCallback(() => {
    localStorage.removeItem(localStorageAccessTokenKey);
    localStorage.removeItem(localStorageRefreshTokenKey);
    setAccessToken(undefined);
    setRefreshToken(undefined);
    setIsAuthenticated(false);
  }, []);

  // Functions
  const logout = useCallback(async (): Promise<void> => {
    const gapiAuth2 = window.gapi?.auth2;
    // if you are not logged in with google api just remove the tokens
    if (!gapiAuth2) {
      removeTokens();
      return;
    }
    const auth2Instance: ExtendedAuth = gapiAuth2.getAuthInstance();

    // otherwise sign out from it first
    // auth2Instance isn't a promise, thanks eslint
    if (!auth2Instance) return;
    await auth2Instance.signOut();
    auth2Instance.disconnect();
    removeTokens();
  }, [removeTokens]);

  const sendPhoneNumber = async (phoneNumber: string) => {
    void client.mutate<RequestLoginCodeForMerchantMutation>({
      variables: {
        input: {
          phone: {
            countryCode: '254',
            number: phoneNumber,
          },
        },
      },
      mutation: RequestLoginCodeForMerchantDocument,
    });
  };
  const makeLoginWithCode = async (code: string, phoneNumber: string) => {
    try {
      const result = await client.mutate<LoginWithCodeForMerchantMutation>({
        variables: {
          input: {
            code: code,
            phone: {
              countryCode: '254',
              number: phoneNumber,
            },
          },
        },
        mutation: LoginWithCodeForMerchantDocument,
      });

      const response = result.data;
      const accessToken = response?.loginWithCodeForMerchant?.accessToken;
      const refreshToken = response?.loginWithCodeForMerchant?.refreshToken;

      if (accessToken && refreshToken) {
        toast.success('Login Successful, Please wait for redirection');
        setTokens(accessToken, refreshToken);
      } else {
        toast.error('Login failed. Please try again.');
        console.error('Unexpected response structure:', response);
      }
    } catch (error) {
      if (error instanceof ApolloError) {
        const errorMessage = error.graphQLErrors
          .map((e) => e.message)
          .join(', ');
        toast.error(`Login failed: ${errorMessage}`);
      } else {
        toast.error('An unexpected error occurred. Please try again.');
        console.error('Login error:', error);
      }
    }
  };
  const signupWithProvider = async (
    providerToken: string,
    provider: Provider
  ) => {
    const result = await client.mutate<SigninOrSignupWithProviderMutation>({
      variables: {token: providerToken, provider},
      mutation: SigninOrSignupWithProviderDocument,
    });

    const response = result.data;
    const accessToken = response?.merchantLoginWithProvider?.accessToken;
    const refreshToken = response?.merchantLoginWithProvider?.refreshToken;

    if (accessToken && refreshToken) {
      toast.success('Login Successfull, Please wait for redirection');
      setTokens(accessToken, refreshToken);
    }
  };

  const mockLogin = () => {
    setTokens('123', '123');
    toast.success('Mock login successful');
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        mockLogin,
        logout,
        sendPhoneNumber,
        makeLoginWithCode,
        signupWithProvider,
        getNewTokens,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
