import {PropsWithChildren} from 'react';
import {Navigate, useLocation} from 'react-router-dom';

import Layout from '../pages/home/Layout';
import {useAuth} from '../utils/auth';

const RequireAuthLayout = () => {
  return (
    <RequireAuth>
      <Layout />
    </RequireAuth>
  );
};

const RequireAuth = ({children}: PropsWithChildren) => {
  const {isAuthenticated} = useAuth();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{from: location}} replace />;
  }
  return <>{children}</>;
};

export default RequireAuthLayout;
