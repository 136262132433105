import {Button} from '@cashiaApp/web-components';
import React from 'react';

import smsIcon from '../../assets/icons/sms_Icon_p.svg';
import LogoSvg from '../../assets/logos/cashia_logo_pink.svg';

const Email_Validation = () => {
  return (
    <div className="flex flex-col min-h-screen justify-around items-center">
      <div className="hidden md:flex w-full my-10">
        <img
          src={LogoSvg}
          alt="logo"
          className="xl:w-[150px] xl:h-[50px] w-[120px] h-[40px]"
        />
      </div>
      <div className="flex flex-col items-center justify-center text-center gap-4 px-4 w-full max-w-xl">
        <div className="flex justify-center">
          <img
            src={smsIcon}
            alt="lock icon"
            className="w-12 h-12 md:w-20 md:h-20"
          />
        </div>
        <p className="font-metropolis font-semibold text-xl md:text-2xl">
          Verify your Email
        </p>
        <div className="flex flex-col gap-2 text-center">
          <p className="font-Metropolis text-foggy px-4 md:px-0">
            We&apos;ve sent you an email to{' '}
            <span className="font-bold text-cashia-grey">
              example@gmail.com
            </span>
            . Simply click the link to verify your account.
          </p>
          <p className="font-Metropolis text-foggy px-4 md:px-0">
            If your inbox is still empty, take a look in your spam folder.
          </p>
          <p className="font-Metropolis text-foggy px-4 md:px-0">
            If you still haven&apos;t received it, please double check that you
            entered the correct email address.
          </p>
        </div>
        <Button
          type="submit"
          className="bg-smoothRed text-white w-full max-w-xs md:max-w-md h-12 my-6">
          Resend Verification Link
        </Button>
      </div>
      <p className="text-foggy font-metropolis text-sm md:text-base mt-10">
        © {new Date().getFullYear()} Cashia Limited. All Rights Reserved.
      </p>
    </div>
  );
};

export default Email_Validation;
