import React from 'react';
import {useNavigate} from 'react-router-dom';

import ShopAddIcon from '../../assets/icons/shop_add.svg';

const ActivateAccount = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div
        className="p-9 w-full rounded-[10px] text-white"
        style={{
          background:
            'radial-gradient(386.74% 439.6% at 140.65% 449.4%, #FECED8 0%, #DC1F5C 100%)',
        }}>
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="flex flex-col gap-[15px] md:mb-0 mb-4">
            <p className="font-bold text-[24px] md:text-[34px]">
              Activate your Account
            </p>
            <p>
              Create a business profile and activate your account to begin using
              Cashia services.
            </p>
          </div>
          <button
            onClick={() => navigate('/home/self-onboarding/business-info')}
            className="font-bold h-12 rounded-lg bg-white text-smoothRed w-full md:w-[286px] flex flex-row items-center justify-center p-4">
            <img src={ShopAddIcon} />
            <p className="ml-4">Create your business profile</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ActivateAccount;
