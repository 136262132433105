import {Input} from '@cashiaApp/web-components';
import React from 'react';

type InputProps = {
  label: string;
  onChange: (value: string) => void;
  value?: string;
  moreInfo?: string;
  error?: JSX.Element | null;
  required?: boolean;
};

export default function FormInput({
  error,
  label,
  onChange,
  value,
  moreInfo,
  required,
}: InputProps) {
  return (
    <div className="mb-3">
      <p className="flex font-[600] mb-1 gap-2 items-center text-sm">
        {label} {required ? '*' : ''} {error ? error : ''}
      </p>
      {moreInfo && (
        <p className="text-[12px] font-[400] text-foggy">{moreInfo}</p>
      )}
      <Input
        onChange={(e) => onChange(e.target.value)}
        name={label}
        value={value}
        className="w-[490px] bg-transparent border-dividerGrey h-[46px] focus:outline-none focus:ring-0 focus:border-red-300"
      />
    </div>
  );
}
