import {Button, toast} from '@cashiaApp/web-components';
import React, {useEffect, useMemo, useState} from 'react';

import FormInput from './FormInput';
import {ReactComponent as DropdownIcon} from '../../../assets/icons/dropdown.svg';
import {ReactComponent as ErrorIcon} from '../../../assets/icons/info-circle.svg';
import DropDown from '../../../components/common/DropDown';
import {tempBankData} from '../../../utils/mocks/banks';
import {useSelfOnboardingContentContext} from '../layout';

export interface BankInfoForm {
  accountNo?: string;
  accountHolderName?: string;
  bank?: string;
}

const BankInfo = () => {
  const [data, setData] = useState<BankInfoForm>();
  const [AccNoError, setAccNoError] = useState('');
  const [AccHolderNameError, setAccHolderNameError] = useState('');
  const {update, payload, goBack} = useSelfOnboardingContentContext();

  useEffect(() => {
    if (payload && payload.bankInfo) {
      setData({...payload.bankInfo});
    }
  }, [payload]);

  const handleAccNoChange = (inputValue: string) => {
    if (/^\d*$/.test(inputValue)) {
      setData({...data, accountNo: inputValue});

      if (inputValue.length < 10) {
        setAccNoError('Account number must be at least 10 digits.');
      } else if (inputValue.length > 14) {
        setAccNoError('Account number must not exceed 14 digits.');
      } else {
        setAccNoError('');
      }
    } else {
      setAccNoError(
        'Please enter a valid Account Number using only numeric characters.'
      );
    }
  };

  const handleAccHolderNameChange = (inputValue: string) => {
    setData({...data, accountHolderName: inputValue});

    if (inputValue.length < 5) {
      setAccHolderNameError(
        'Account Holder Name must be at least 5 characters long.'
      );
    } else if (inputValue.length > 100) {
      setAccHolderNameError(
        'Account Holder Name must not exceed 100 characters.'
      );
    } else {
      setAccHolderNameError('');
    }
  };

  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  const isDisabled = useMemo(() => {
    return (
      !data?.bank ||
      !data.accountNo ||
      !data.accountHolderName ||
      !!AccNoError ||
      !!AccHolderNameError
    );
  }, [data, AccNoError, AccHolderNameError]);

  return (
    <div className="flex flex-col justify-between h-full">
      <div>
        <div>
          <p className="text-black text-sm font-semibold">Step 3:</p>
          <p className="text-black text-3xl font-bold mb-4">
            Bank Account Details
          </p>
        </div>
        <div className="mb-5">
          <p className="text-sm font-semibold text-black">Bank name *</p>

          <DropDown
            dropDownPosition="center"
            show={dropdownOpen}
            setShow={setDropdownOpen}
            actionElement={
              <div
                className={`${
                  dropdownOpen ? 'border-red-300' : 'border-dividerGrey'
                } px-[10px] flex flex-row items-center justify-between w-[490px] bg-transparent border-[1px] rounded-[5px] h-[46px] focus:outline-none focus:ring-0 focus:border-red-300`}>
                <p className="w-[450px] overflow-hidden truncate text-[14px]">
                  {data?.bank}
                </p>
                <DropdownIcon />
              </div>
            }>
            <div className="shadow-sm cursor-pointer w-[490px] h-[412px] overflow-y-scroll ">
              {tempBankData.map((item) => (
                <div
                  onClick={() => {
                    setData({...data, bank: item.name});
                    setDropdownOpen(false);
                  }}
                  key={item.name}
                  className="hover:text-red-500 cursor-pointer flex items-center bg-white px-4 py-2 border-b-[1px] border-dividerGrey px-3 py-3">
                  <p className="text-[14px] font-[500]">{item.name}</p>
                </div>
              ))}
            </div>
          </DropDown>
        </div>
        <FormInput
          label="Account Holder Name"
          required
          error={
            AccHolderNameError ? (
              <div className="flex items-center border rounded-lg p-1 mb-2 bg-lightOrange">
                <ErrorIcon className="items-center pr-1" />
                <p className="text-red-500 text-[10px] font-medium items-center ">
                  {AccHolderNameError}
                </p>
              </div>
            ) : null
          }
          value={data?.accountHolderName}
          onChange={handleAccHolderNameChange}
        />
        <FormInput
          label="Account Number"
          required
          error={
            AccNoError ? (
              <div className="flex items-center border rounded-lg p-1 mb-1 bg-lightOrange">
                <ErrorIcon className="items-center pr-1" />
                <p className="text-red-500 text-[10px] font-medium items-center ">
                  {AccNoError}
                </p>
              </div>
            ) : null
          }
          value={data?.accountNo}
          onChange={handleAccNoChange}
        />
      </div>

      <div className="flex flex-row justify-between">
        <Button
          className="cursor-pointer w-[237px] h-[48px] border-lightRed border-[1.2px] rounded-[8px] flex items-center justify-center bg-white"
          onClick={goBack}>
          <p className="text-active-red font-[600]">Previous</p>
        </Button>
        <Button
          className={`w-[237px] h-[48px] rounded-[8px] flex items-center justify-center bg-lightGrey cursor-pointer ${
            isDisabled
              ? 'bg-lightGrey font-semibold text-foggy'
              : 'bg-smoothRed text-white'
          }`}
          onClick={() => {
            if (!isDisabled) {
              update?.({...payload, bankInfo: {...data}});
              toast.success('Bank account info captured!');
            }
          }}>
          <p
            className={`${
              isDisabled ? 'text-disabledText' : 'text-white'
            } font-[600]`}>
            Next
          </p>
        </Button>
      </div>
    </div>
  );
};

export default BankInfo;
