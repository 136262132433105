import {Checkbox, toast, Button} from '@cashiaApp/web-components';
import React, {useEffect, useMemo} from 'react';
import Dropzone from 'react-dropzone';
import {useNavigate} from 'react-router-dom';

import FormInput from './FormInput';
import {ReactComponent as CloseModalIcon} from '../../../assets/icons/close-modal.svg';
import {ReactComponent as DeleteIcon} from '../../../assets/icons/delete_icon.svg';
import {ReactComponent as DropdownIcon} from '../../../assets/icons/dropdown.svg';
import {ReactComponent as Info} from '../../../assets/icons/info-circle.svg';
import {ReactComponent as ModalUploadIcon} from '../../../assets/icons/modal-upload-icon.svg';
import {ReactComponent as TickIcon} from '../../../assets/icons/tick.svg';
import {ReactComponent as UploadIcon} from '../../../assets/icons/uploadIcon.svg';
import PlacesAutocomplete from '../../../components/common/AddressInput';
import DropDown from '../../../components/common/DropDown';
import Modal from '../../../components/tailwind/Modal';
import {useSelfOnboardingContentContext} from '../layout';

export interface BussinessOwnerInfoForm {
  firstName?: string;
  lastName?: string;
  idType?: string;
  id?: File;
  address?: string;
  building?: string;
}

interface FormErrors {
  [key: string]: string;
}

type ValidateFunction = (values: BussinessOwnerInfoForm) => FormErrors;

const validate: ValidateFunction = (data: BussinessOwnerInfoForm) => {
  const errors: FormErrors = {};
  if (!data?.firstName?.trim()) {
    errors.firstName = 'First name is required';
  }
  if (!data?.lastName?.trim()) {
    errors.lastName = 'Last name is required';
  }
  if (!data?.idType?.trim()) {
    errors.idType = 'ID type is required';
  }
  if (!data?.id) {
    errors.id = 'Identity document is required';
  }
  if (!data?.address?.trim()) {
    errors.address = 'Address is required';
  }

  return errors;
};

const idTypes = ['National ID', 'Passport'];

export default function BusinessOwnerInfo() {
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [copyBizAddress, setCopyBizAddress] = React.useState(false);
  const [file, setFile] = React.useState<File>();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [data, setData] = React.useState<BussinessOwnerInfoForm>({
    firstName: '',
    lastName: '',
    idType: '',
    address: '',
    building: '',
  });
  const [errors, setErrors] = React.useState<FormErrors>({});
  const {update, payload} = useSelfOnboardingContentContext();

  useEffect(() => {
    if (payload && payload.ownerInfo) {
      setData({...payload.ownerInfo});
      setFile(payload.ownerInfo.id);
    }
  }, [payload]);

  const handleFormSubmit = () => {
    setErrors({});
    const validationErrors = validate(data);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      update?.({
        ...payload,
        ownerInfo: {...data},
      });
      toast.success('Business owner info stored!');
    }
  };

  const btnDisabled = useMemo(() => {
    return (
      !data?.firstName || !data?.lastName || !data?.idType || !data?.address
    );
  }, [data]);

  const renderErrorMessage = (fieldName: string) => {
    if (errors[fieldName]) {
      return (
        <div className="flex items-center h-[24px]">
          <Info />
          <span className="text-orangeDark mx-2 p-1 rounded text-[12px] font-normal bg-lightOrange">
            {errors[fieldName]}
          </span>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="flex flex-col justify-between h-full">
      <form>
        <div>
          <p className="font-[600]">Step 2:</p>
          <p className="mb-3 font-[600] text-[40px] leading-[40px]">
            Business Onwer Information
          </p>
          <FormInput
            label="First Name"
            required
            error={renderErrorMessage('firstName')}
            moreInfo="First name must match identity document"
            onChange={(e) => setData({...data, firstName: e})}
            value={data?.firstName?.replaceAll(' ', '')}
          />
          <FormInput
            label="Last Name"
            required
            error={renderErrorMessage('lastName')}
            moreInfo="Last name must match identity document"
            onChange={(e) => setData({...data, lastName: e})}
            value={data?.lastName?.replaceAll(' ', '')}
          />
          <div className="mb-4">
            <p className="flex font-[600] mb-1 gap-2 items-center text-sm">
              Identification Document Type * {renderErrorMessage('idType')}
            </p>
            <DropDown
              dropDownPosition="center"
              show={dropdownOpen}
              setShow={setDropdownOpen}
              actionElement={
                <div
                  className={`${
                    dropdownOpen ? 'border-red-300' : 'border-dividerGrey'
                  } px-[10px] flex flex-row items-center justify-between w-[490px] bg-transparent border-[1px] rounded-[5px] h-[46px] focus:outline-none focus:ring-0 focus:border-red-300`}>
                  <p className="w-[450px] overflow-hidden truncate text-[14px]">
                    {data.idType}
                  </p>
                  <DropdownIcon />
                </div>
              }>
              <div className="shadow-sm cursor-pointer w-[490px] h-[100px] overflow-y-scroll ">
                {idTypes.map((type, i) => (
                  <div
                    onClick={() => {
                      setDropdownOpen(false);
                      setData({...data, idType: type});
                    }}
                    key={type}
                    className={`hover:text-red-500 cursor-pointer flex items-center bg-white px-4 py-2 ${
                      i !== idTypes.length - 1
                        ? 'border-b-[1px] border-dividerGrey'
                        : ''
                    } px-3 py-3`}>
                    <p className="text-[14px] font-[500]">{type}</p>
                  </div>
                ))}
              </div>
            </DropDown>
          </div>

          {errors.id && <div className="mb-2">{renderErrorMessage('id')}</div>}
          <div className="flex items-center gap-4 mb-[15px]">
            <div
              onClick={() => {
                setModalOpen(true);
              }}
              className={`cursor-pointer flex items-center justify-center w-[138px] h-[88px] border-[1.2px] rounded-[10px] ${
                errors.id
                  ? 'border-red-300 border-[1px]'
                  : 'border-lightenedGrey'
              }`}>
              {data?.id ? (
                <div>
                  <img
                    alt=""
                    className="cursor-pointer h-[88px] w-[150px] rounded-[5px]"
                    src={URL.createObjectURL(data.id)}
                  />
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <UploadIcon />
                </div>
              )}
            </div>
            <div>
              <p className="text-[14px] font-[500] leading--[14px]">
                Copy of Valid Proof of Identity
              </p>
              <p className="text-[12px] font-[500] leading--[12px] text-greyish">
                PNG, JPG up to 5MB
              </p>
              <div
                onClick={() => setModalOpen(true)}
                className="cursor-pointer">
                <p className="text-[12px] font-[600] leading--[12px] text-disabledText">
                  {data?.id ? 'Update Image' : 'Upload Image'}
                </p>
              </div>
            </div>
          </div>
          <div>
            <p className="flex font-[600] mb-1 gap-2 items-center text-sm">
              Residential Address * {renderErrorMessage('address')}
            </p>
            <PlacesAutocomplete
              onSelect={(value) => setData({...data, address: value})}
              defaultValue={data.address}
            />
            <div className="flex items-center gap-2 text-[14px] mb-[15px]">
              <Checkbox
                checked={copyBizAddress}
                onChange={() => {
                  if (!copyBizAddress) {
                    setData({
                      ...data,
                      address: payload?.buisnessInfo?.location,
                    });
                  } else {
                    setData({...data, address: ''});
                  }
                  setCopyBizAddress(!copyBizAddress);
                }}
                className="w-[14px] h-[14px] border-dividerGrey"
              />
              <p className="font-[400] text-[14px]">
                Is this the same as business address
              </p>
            </div>
          </div>

          <FormInput
            label="Complex / Building Name"
            onChange={(e) => setData({...data, building: e})}
            value={data.building}
          />
        </div>
      </form>
      <div className="flex flex-row justify-between">
        <Button
          onClick={() => navigate('/home/self-onboarding/business-info')}
          className="cursor-pointer w-[237px] h-[48px] border-lightRed border-[1.2px] rounded-[8px] flex items-center justify-center bg-white">
          <p className="text-active-red font-[600]">Previous</p>
        </Button>
        <Button
          className={`w-[237px] h-[48px] rounded-[8px] flex items-center justify-center bg-lightGrey cursor-pointer ${
            btnDisabled
              ? 'bg-lightGrey font-semibold text-foggy'
              : 'bg-smoothRed text-white'
          }`}
          onClick={handleFormSubmit}>
          <p
            className={`${
              btnDisabled ? 'text-disabledText' : 'text-white'
            } font-[600]`}>
            Next
          </p>
        </Button>
      </div>

      <Modal
        isVisible={modalOpen}
        onClose={() => setModalOpen(false)}
        className="w-[690px] h-[524px]">
        <div className="flex flex-col min-w-[690px] h-[524px] pt-[35px] pb-[48px] px-[48px] bg-lightGrey rounded-[10px]">
          <div className="flex justify-between">
            <div>
              <p className="font-[600] text-[28px] leading-[24px] pb-3">
                Upload a Valid Proof of Identity
              </p>
              <p className="font-[400] text-[14px] leading-[14px] text-foggy">
                Provide a valid ID document
              </p>
            </div>
            <CloseModalIcon
              className="cursor-pointer"
              onClick={() => setModalOpen(false)}
            />
          </div>
          {file ? (
            <div className="w-[600px] h-[240px] border-[2px] border-dashed border-[#ccc] rounded-[10px] flex flex-col justify-center items-center relative cursor-pointer p-[20px] mt-[20px] bg-white">
              <div
                className="absolute left-[550px] bottom-[180px] cursor-pointer"
                onClick={() => {
                  setFile(undefined);
                }}>
                <DeleteIcon />
              </div>
              <div className="cursor-pointer h-[150px] w-[237px] mb-3 rounded-[5px]">
                <img
                  alt=""
                  className="cursor-pointer h-[150px] w-[237px] rounded-[5px]"
                  src={URL.createObjectURL(file)}
                />
              </div>
              <div className="flex items-center gap-3">
                <TickIcon />
                <p className="font-[700] text-foggy">Upload Successful!</p>
              </div>
            </div>
          ) : (
            <Dropzone
              accept={{
                'image/*': ['.jpeg', '.png', '.jpg'],
              }}
              minSize={0}
              maxSize={5242880}
              onDrop={async (acceptedFiles) => {
                if (acceptedFiles.length) {
                  setFile(acceptedFiles[0]);
                }
              }}
              onDropRejected={() => {
                toast.error(
                  'Image too large. Please check the size of the file. Maximum size is 5MB'
                );
              }}>
              {({getRootProps, getInputProps}) => (
                <div
                  className="w-[600px] h-[240px] border-[2px] border-dashed border-[#ccc] rounded-[10px] flex flex-col justify-center items-center relative cursor-pointer p-[20px] mt-[20px] bg-white"
                  {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="flex items-start justify-start p-[10px] mb-[10px] w-full flex-col items-center gap-3">
                    <ModalUploadIcon />
                    <div>
                      <p className="text-[16px] leading-[20px] font-[500] text-disabledText">
                        Drag and drop file here or{' '}
                        <span className="text-pink">Choose file</span>
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </Dropzone>
          )}
          <div className="flex justify-between w-full my-[20px]">
            <p className="text-[14px]">SUPPORTED FORMATS: PNG, JPEG</p>
            <p className="text-[14px]">MAXIMUM SIZE: 5MB</p>
          </div>
          <div className="flex flex-row justify-between">
            <Button
              onClick={() => setModalOpen(false)}
              className="cursor-pointer w-[237px] h-[48px] border-lightRed border-[1.2px] rounded-[8px] flex items-center justify-center bg-white">
              <p className="text-active-red font-[600]">Cancel</p>
            </Button>
            <Button
              onClick={() => {
                setData({...data, id: file});
                setModalOpen(false);
              }}
              className={`w-[237px] h-[48px] rounded-[8px] flex items-center justify-center cursor-pointer ${
                !file
                  ? 'bg-lightenedGrey font-semibold text-foggy'
                  : 'bg-smoothRed text-white'
              }`}>
              <p
                className={`${
                  !file ? 'text-disabledText' : 'text-white'
                } font-[600]`}>
                Upload
              </p>
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
